/*
 * This file combines the carboncrop theme settings with bootstrap
 *
 * This should be imported ONLY from App.tsx. Please do not import it
 * elsewhere to prevent duplicate CSS from being emitted.
 *
 * These are global CSS styles, so be cautious about adding things here.
 * you may want to make a component module instead.
 *
 * For more info, see:
 * https://carboncrop.atlassian.net/wiki/spaces/CAR/pages/200671233/CSS+Bundling
 */

@import './colors.scss';
@import './carboncrop.scss';
@import './printable.scss';

@import 'node_modules/bootstrap/scss/bootstrap';

@import 'table_colored_spaced.scss';

// Override background colors for Modal to increase contrast
.modal-content {
  background-color: $cc-offwhite;
}

.modal-header {
  color: map-get($theme-colors, 'dark');
  border: none;
}

.modal-footer {
  border: none;
}

// Special 'cc' variant button that has an outline
.btn-cc-primary {
  border: 1px solid map-get($theme-colors, 'primary');
  background-color: map-get($theme-colors, 'primary');
  color: map-get($theme-colors, 'dark');
}

.btn-cc-primary:hover {
  background-color: map-get($theme-colors, 'dark');
  color: map-get($theme-colors, 'primary');
}

.btn-cc-rev-primary {
  border: none;
  color: map-get($theme-colors, 'dark');
}

.btn-cc-rev-primary:hover {
  background-color: map-get($theme-colors, 'primary');
}

.list-group-item {
  --bs-list-group-bg: $cc-offwhite;
}

.alert-success {
  background-color: scale-color($sec-parakeet, $lightness: 70%);
  color: $sec-deep-bush;
}

.alert-warning {
  background-color: scale-color($sec-tui, $lightness: 80%);
  color: $sec-kiwi;
}

.alert-dark {
  background-color: scale-color($grey-80);
  color: $cc-charcoal;
}

// Add success toast styling
.toast.bg-success {
  background-color: scale-color($cc-lime, $lightness: 50%) !important;
  color: $cc-charcoal;
}

.toast.bg-warning {
  background-color: scale-color($sec-tui, $lightness: 80%) !important;
  color: $sec-kiwi;
}

.btn-link-dark:hover {
  text-decoration: underline;
}

.btn-link-light {
  color: $cc-offwhite;
}

.btn-link-pink {
  color: $pink-55;
}
.btn-link-pink:hover {
  text-decoration: underline;
}

body > #root {
  height: 100vh;
}

.mapboxgl-ctrl-top-left {
  min-width: 50%;
  max-width: 75%;
}
.mapboxgl-ctrl-geocoder {
  border-radius: 0 !important;
}
.mapboxgl-ctrl-group {
  border-radius: 0 !important;
}
.mapboxgl-popup-content {
  border-radius: 0 !important;
}

body a {
  color: inherit;
  text-decoration: underline;
}

.dropdown-toggle {
  width: 100%;
  height: 38px;
  text-align: left;
  display: flex;
  align-items: center;
}

.dropdown-toggle.btn-sm {
  height: 30px;
}

.dropdown-no-toggle::after {
  display: none !important;
}

.dropdown-toggle::after {
  margin-left: auto;
}

.dropdown-menu {
  min-width: 100%;
}
.dropdown-item {
  min-height: 32px;
  &:active,
  &.active {
    background-color: $cc-lime !important;
    color: black !important;
  }
}

.dropdown-item:hover {
  background-color: $cc-lime;
}

.dropdown-toggle-white {
  background-color: white;
}

// Bootstrap 5 has rounded-pill for rounding on all sides, but
// our branding has quite a few rounded just on start/end
.rounded-pill-start {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-pill-end {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

// Some custom color classes
.text-nzu-blue {
  color: $nzu-blue;
}

.bg-birch {
  background-color: $sec-birch !important;
}

.bg-lime {
  background-color: $cc-lime;
}

.border-dashed {
  --bs-border-style: dashed;
}

// We use a bunch of super-sized borders as stylistic elements
.border-6 {
  --bs-border-width: 2rem;
}

.tiny {
  font-size: 0.75rem;
}

// Cannot click on this div, but can click on all children
.pe-children-only {
  pointer-events: none;
}
.pe-children-only * {
  pointer-events: auto;
}

.bg-hover-white:hover {
  background-color: white !important;
  transition: background-color 0.5s;
}

.bg-hover-grey-95:hover {
  background-color: $grey-95 !important;
  transition: background-color 0.5s;
}

.bg-hover-grey-40:hover {
  background-color: $grey-40 !important;
  transition: background-color 0.5s;
}

.bg-hover-birch:hover {
  background-color: $sec-birch !important;
  transition: background-color 0.5s;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-company-header {
  background-color: var(--company-header-color, $cc-lime);
}

.text-company-header-text {
  color: var(--company-header-text-color, $cc-charcoal);
}

.form-label-disabled {
  opacity: 0.5;
}

.form-select-disabled {
  background-color: var(--bs-secondary-bg) !important;
  opacity: 1 !important;
}

.border-muted {
  border-color: $grey-50 !important;
}

.link-muted {
  color: $grey-50 !important;
}

.link-muted:hover {
  color: $grey-22 !important;
}

// Map layer toggle button
.layer-group-btn:hover .hovered-icon-offwhite {
  color: $cc-offwhite;
}

// Map layer description
.layer-description p {
  margin-bottom: 0px;
}

@media screen {
  .bg-birch-screen {
    // When on a screen make it easy to see where the page starts/stop
    background-color: $sec-birch;
  }
  .bg-light-screen {
    // When on screen, attempt to match paper stock according to
    // the brand guidelines
    background-color: $cc-offwhite;
  }

  .d-none-screen {
    // Hide elements only when on screen
    display: none;
  }

  .shadow-massive {
    box-shadow: 0 0 40px rgba($cc-charcoal, 0.2);
  }
}

// Hover background effect for navigation links
.hover-bg {
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
